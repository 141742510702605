import React, { useState, useEffect } from "react"

const InstagramFeed = () => {

  const [instagramPosts, setInstagramPosts] = useState([]);
  useEffect(() => {
    fetch(`/api/instagram/posts`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
          // console.log("Instagram posts =", resultData);
          setInstagramPosts(resultData);
      })
  }, [])
  
    return (
        <div className="col-span-full container relative grid grid-cols-[repeat(3,auto)] md:grid-cols-[repeat(3,auto)] lg:grid-cols-[repeat(6,auto)] auto-rows-fr gap-4 justify-between mt-4 mb-32 after:block after:bg-dl-section-bg-orange after:-bottom-16 after:-right-4 after:z-0 after:absolute after:w-5/12 after:h-3/4">
            {instagramPosts.map((post) =>
            <div className="grid group z-10" key={post.id}>
                <div className="col-span-full row-span-full z-10 overflow-hidden transition duration-300 ease-in-out group-hover:bg-black group-hover:bg-opacity-20">
                    <a href={post.permalink} target="_blank" title={post.caption} rel="noreferrer">
                      <img src={post.media_type === 'VIDEO' ? post.thumbnail_url : post.media_url} alt={post.caption} className="w-full h-full object-cover transform transition duration-300 ease-in-out group-hover:scale-110 group-hover:duration-300 mix-blend-overlay" />
                    </a>
                </div>
            </div>
            )}
        </div>
      )
};

export default InstagramFeed;